import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutNav from "../components/about-nav"

const TaiChiHistoryPage = () => {
  const data = useStaticQuery(graphql`
    query HistoryPageQuery {
      site {
        siteMetadata {
          title
          pageTitles {
            history
          }
        }
      }
    }
  `)
  const { title: siteTitle, pageTitles } = data.site.siteMetadata

  return (
    <Layout>
      <Seo title={`${siteTitle} | ${pageTitles.history}`} />
      <section className="textual">
        <div className="wrapper">
          <h1>{pageTitles.history}</h1>
          <div className="block twothirdcol">
            <div className="block col1">
              <p>
                Taijiquan is a Chinese internal martial art based on the
                philosophy of Taiji. It is also spelled T’ai Chi Ch’uan, with
                the name often being simplified to Taiji or T’ai Chi in common
                usage. Taijiquan includes elements of various martial arts,
                qigong ("internal energy cultivation"), Chinese philosophy and
                traditional Chinese medicine (TCM), and is said to capture the
                essence of traditional Chinese culture.
              </p>
              <p>
                Modern day Taijiquan has many manifestations and serves a wide
                variety of needs. Some practice Taijiquan as a slow, graceful
                exercise for health maintenance and longevity. Others study it
                as a traditional martial art, equally emphasizing the fast
                paced, explosive combat aspects along with the softer movements.
                Still others perform aesthetic Taijiquan routines which have
                been standardized for wushu sport competitions. Given its strong
                meditative aspect, Taijiquan is also practiced as a form of
                "meditation in motion."
              </p>
              <p>
                Regardless of the style, sect or school, all popularly
                recognized forms of Taijiquan are rooted in Taiji philosophy and
                emphasize the power of muscular relaxation, effective use of
                leverage and full body coordination, rather than application of
                brute force in motion.
              </p>
              <p>
                This unique practice has attracted people from around the world,
                with an estimated 100 to 150 million practitioners studying
                Taijiquan today (according to the China’s National Wushu
                Research Institute). And after centuries of development and
                refinement by generations of masters, Taijiquan is still a
                living, breathing art, one that is continuously innovating and
                evolving to meet the needs of people in today’s society.
              </p>
            </div>
            <div className="block col2">
              <AboutNav current="history" />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default TaiChiHistoryPage
